import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  UNSAFE_NavigationContext,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  assignActivity,
  assignJournal,
  assignWorksheet,
  cancelSession,
  editActivityy,
  editJournall,
  editWorksheett,
  getActivitiess,
  getJournals,
  getSessionData,
  getSessionHistory,
  getTempSessionHistory,
  getWorksheets,
  markAsComplete,
  saveSessionDoc,
  saveSessionDocumentation,
  uploadSessionPDF,
} from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import ClinicianHeader from "../commonComponent/clinicianHeader";
import ClinicianSidebar from "../commonComponent/clinicianSidebar";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { allowForSilverPlan } from "../../apiServices/userHttpService/adminUserHttpService";
import $ from "jquery";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import OwlCarousel from "react-owl-carousel";
import { useForm } from "react-hook-form";
import { MDBDataTable } from "mdbreact";
import Activity from "./activity";
import Journal from "./journal";
import { docData } from "../../atom";
import { useRecoilValue, useSetRecoilState } from "recoil";

function ClinicianViewUpcoming() {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleFormChange = () => {
    setHasUnsavedChanges(true);
  };
  let location = useLocation();
  let sessionType = location?.state?.type;
  let monthType = location?.state?.month;
  const [upcomingData, setUpcomingData] = useState("");
  const [compReason, setCompReason] = useState("");
  const [join, setJoin] = useState("");
  const [notJoin, setNotJoin] = useState("");
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [indexS1, setIndexS1] = useState(0);
  const [indexS2, setIndexS2] = useState(0);
  const [indexS3, setIndexS3] = useState(0);
  const [indexS4, setIndexS4] = useState(0);
  const [index2S1, setIndex2S1] = useState(0);
  const [index2S2, setIndex2S2] = useState(0);
  const [index2S3, setIndex2S3] = useState(0);
  const [index2S4, setIndex2S4] = useState(0);
  const [getSession, setGetSession] = useState([]);
  const [monthIndex, setMonthIndex] = useState(0);
  const [sessionIndex, setSessionIndex] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [editActivity, setEditActivity] = useState(false);
  const [editJournal, setEditJournal] = useState(false);
  const [editWorksheet, setEditWorksheet] = useState(false);
  const [relation, setRelation] = useState("In-person");
  const [type, setType] = useState(0);
  const [userData, setUserData] = useState(docData);
  const setDocData = useSetRecoilState(docData);
  const documentData = useRecoilValue(docData);

  useEffect(() => {
    switch (sessionType) {
      case "Introductory Meeting":
        setSessionIndex(0);
        break;
      case "Treatment Planning":
        setSessionIndex(3);
        break;
      case "Individual Intake - Mother":
        setSessionIndex(1);
        break;
      case "Individual Intake - Daughter":
        setSessionIndex(2);
        break;
      case "Video Session 1":
        if (monthType) {
          setMonthIndex(monthType - 1);
          setSessionIndex(0);
        }
        break;
      case "Video Session 2":
        if (monthType) {
          setMonthIndex(monthType - 1);
          setSessionIndex(1);
        }
        break;
      default:
        setSessionIndex(0);
        break;
    }
  }, [sessionType]);

  console.log(sessionIndex, sessionType, monthType);

  const handleData = async (e, str, sessionType) => {
    const value = e.target.value;
    let sessionIds = getSession[monthIndex].sessions[sessionIndex]._id;
    console.log(sessionId);
    const formData = new FormData();
    formData.append("sessionId", sessionIds);
    formData.append(str, value);
    formData.append("type", 1);

    const { data } = await saveSessionDocumentation(formData);
    if (!data.error) {
      console.log(data);
      if (type === 3) {
        document.getElementById("close_reason").click();
      }
      setType(0);
    }
  };

  const [activity, setActivity] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Message",
        field: "description",
        sort: "asc",
        width: 150,
      },

      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const [journal, setJournal] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: "Topic",
        field: "topic",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const [worksheet, setWorksheet] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: "Message",
        field: "description",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pdf",
        field: "pdf",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const { navigator } = useContext(UNSAFE_NavigationContext);
  let { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (isDirty) navigator.block();
  }, [navigator]);

  useEffect(() => {
    getDetail();
    getSessionDetail();
    getActListing();
    getJourListing();
    getWorkListing();
  }, [
    monthIndex,
    sessionId,
    editActivity,
    editJournal,
    editWorksheet,
    relation,
    sessionIndex,
  ]);
  console.log(type, compReason);

  const getActListing = async () => {
    const { data } = await getActivitiess({ type: relation });

    if (!data.error) {
      const newRows = [];
      console.log(data);
      data.results.activities.map((list, index) => {
        const returnData = {};

        returnData.sn = index + 1;
        returnData.title = list.title;
        returnData.description = list.message;
        returnData.image = (
          <div className="header-imgg">
            <img src={list.image} alt="" />
          </div>
        );
        returnData.action = (
          <>
            <button
              class="comman_btn table_viewbtn"
              to=""
              onClick={() => assignedActivity(list._id)}
            >
              Send
            </button>
          </>
        );

        newRows.push(returnData);
      });
      setActivity({ ...activity, rows: newRows });
    }
  };

  const getJourListing = async () => {
    const { data } = await getJournals();
    if (!data.error) {
      const newRows = [];
      console.log(data);
      data.results.journals.map((list, index) => {
        const returnData = {};

        returnData.sn = index + 1;
        returnData.topic = list.topic;

        returnData.action = (
          <>
            <button
              class="comman_btn table_viewbtn"
              to=""
              onClick={() => assignedJournal(list._id)}
            >
              Send
            </button>
          </>
        );

        newRows.push(returnData);
      });
      setJournal({ ...journal, rows: newRows });
    }
  };

  const getWorkListing = async () => {
    const { data } = await getWorksheets();
    if (!data.error) {
      const newRows = [];
      console.log(data);
      data.results.worksheets.map((list, index) => {
        const returnData = {};

        returnData.sn = index + 1;
        returnData.description = list.message;
        returnData.pdf = (
          <div className="header-imgg">
            <a href={list.pdf} target="_blank">
              <i
                className="fa fa-file-pdf-o"
                style={{ fontSize: "20px", color: "#000" }}
              ></i>
            </a>
          </div>
        );

        returnData.action = (
          <>
            <button
              class="comman_btn table_viewbtn"
              to=""
              onClick={() => assignedWorksheet(list._id)}
            >
              Send
            </button>
          </>
        );

        newRows.push(returnData);
      });
      setWorksheet({ ...worksheet, rows: newRows });
    }
  };

  const getDetail = async () => {
    const { data } = await getSessionData(id);
    if (!data.error) {
      console.log(data);
      setUpcomingData(data.results.session);
    }
  };

  const getSessionDetail = async () => {
    const { data } = await getSessionHistory(id);
    if (!data.error) {
      console.log(data);
      setGetSession(data.results.session);
      document.getElementById("close-activity").click();
      document.getElementById("close-journal").click();

      let defaultValues = {};

      defaultValues.change_reason =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .change_reason ?? "";
      defaultValues.feedback =
        data.results.session[monthIndex]?.sessions[sessionIndex].feedback ?? "";
      defaultValues.focus_of_session =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .focus_of_session ?? "";

      defaultValues.progress_toward_goals =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .progress_toward_goals ?? "";
      defaultValues.safety_concerns =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .safety_concerns ?? "";
      defaultValues.plan =
        data.results.session[monthIndex]?.sessions[sessionIndex].plan ?? "";

      defaultValues.start_reason =
        data.results.session[monthIndex]?.sessions[sessionIndex].start_reason ??
        "";

      defaultValues.goals =
        data.results.session[monthIndex]?.sessions[sessionIndex].goals ?? "";
      defaultValues.therapy_history =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .therapy_history ?? "";
      defaultValues.observations_and_assessment =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .observations_and_assessment ?? "";
      defaultValues.attendees =
        data.results.session[monthIndex]?.sessions[sessionIndex].attendees ??
        "";

      defaultValues.mother_daughter_relationship =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .mother_daughter_relationship ?? "";
      defaultValues.well_within_relationship =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .well_within_relationship ?? "";
      defaultValues.improve =
        data.results.session[monthIndex]?.sessions[sessionIndex].improve ?? "";
      defaultValues.hope =
        data.results.session[monthIndex]?.sessions[sessionIndex].hope ?? "";
      defaultValues.difficulty_in_relationship =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .difficulty_in_relationship ?? "";
      defaultValues.change =
        data.results.session[monthIndex]?.sessions[sessionIndex].change ?? "";
      defaultValues.previous_experience =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .previous_experience ?? "";
      defaultValues.other_info =
        data.results.session[monthIndex]?.sessions[sessionIndex].other_info ??
        "";
      defaultValues.red_flags_review =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .red_flags_review ?? "";
      defaultValues.mental_health_screen =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .mental_health_screen ?? "";
      defaultValues.social_history =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .social_history ?? "";
      defaultValues.other_pertinent_information =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .other_pertinent_information ?? "";
      defaultValues.observations =
        data.results.session[monthIndex]?.sessions[sessionIndex].observations ??
        "";
      defaultValues.assessment =
        data.results.session[monthIndex]?.sessions[sessionIndex].assessment ??
        "";
      defaultValues.feedback_from_pair =
        data.results.session[monthIndex]?.sessions[sessionIndex]
          .feedback_from_pair ?? "";

      console.log(defaultValues);
      reset({ ...defaultValues });
    }
  };

  const getActivities = (activities, t) => {
    const activity = activities?.filter(({ type }) => type === t);
    if (activity?.length) {
      return activity[0];
    } else {
      return {};
    }
  };

  const assignedActivity = async (id) => {
    const Data = {
      sessionId: sessionId,
      activityId: id,
    };

    console.log(Data);

    const { data } = editActivity
      ? await editActivityy(Data)
      : await assignActivity(Data);
    if (!data.error) {
      console.log(data);
      await getSessionDetail();
      const link = document.getElementById("close");
      link.click();
      setEditActivity(false);
    }
  };

  console.log(getSession);

  const assignedJournal = async (id) => {
    console.log(getSession, "hbjh", monthIndex, editJournal);
    const formData = {
      sessionId: sessionId,
      journalId: id,
    };

    const { data } = editJournal
      ? await editJournall(formData)
      : await assignJournal(formData);
    if (!data.error) {
      console.log(data);
      await getSessionDetail();
      const link = document.getElementById("close2");
      link.click();
      setEditJournal(false);
    }
  };

  const assignedWorksheet = async (id) => {
    const Data = {
      sessionId: sessionId,
      worksheetId: id,
    };

    const { data } = editWorksheet
      ? await editWorksheett(Data)
      : await assignWorksheet(Data);
    if (!data.error) {
      console.log(data);
      await getSessionDetail();
      const link = document.getElementById("close3");
      link.click();
      setEditWorksheet(false);
    }
  };
  {
    console.log(userData, "dsds");
  }

  const onSubmit = async (dataa) => {
    setHasUnsavedChanges(false);

    if (type === 3) {
      if (!compReason) {
        toast.error("Please enter Comment");
        return;
      }
    }

    dataa.sessionId = getSession[monthIndex].sessions[sessionIndex]._id;
    dataa.sign_date = new Date();
    dataa.type = type;
    if (type === 3) {
      dataa.change_reason = compReason;
    }

    const formData = new FormData();
    for (const item in dataa) {
      formData.append(item, dataa[item]);
      console.log(dataa[item]);
    }

    if (selectedFile) {
      formData.append("signature", selectedFile, selectedFile.name);
    } else {
      formData.append(
        "signature",
        getSession[monthIndex]?.sessions[sessionIndex].clinician_signature
      );
    }

    formData.append("isCompleted", true);

    const { data } = await saveSessionDocumentation(formData);
    if (!data.error) {
      console.log(data);
      toast.success(data.message);

      if (type === 3) {
        document.getElementById("close_reason").click();
      }
      setType(0);
    }
    await getSessionDetail();
    setSelectedFile(null);
  };

  const onFileSelection = (event) => {
    let file = event[0];

    setSelectedFile(event[0]);
  };
  const onFileSelection2 = (event) => {
    let file = event[0];

    setSelectedFile2(event[0]);
  };

  const uploadPdf = async () => {
    if (!selectedFile2) {
      toast.error("Please add pdf");
      return;
    }
    const formData = new FormData();

    formData.append(
      "sessionId",
      getSession[monthIndex].sessions[sessionIndex]._id
    );

    if (selectedFile2) {
      formData.append("pdf", selectedFile2, selectedFile2.name);
    }

    const { data } = await uploadSessionPDF(formData);
    if (!data.error) {
      await getSessionDetail();
      document.getElementById("upload_pdf1").value = null;
      setSelectedFile2(null);
    }
  };

  const getCancelReason = async () => {
    const formData = {
      sessionId: id,
      reason: compReason,
    };
    const { data } = await cancelSession(formData);
    if (!data.error) {
      navigate("/clinician/video-session-management");
    }
  };

  const onButtonClick = () => {
    document.getElementById("textInput").className = "show mt-4";
    document.getElementById("textInput2").className = "hide mt-4";
  };

  const onButtonClick2 = () => {
    document.getElementById("textInput2").className = "show mt-4";
    document.getElementById("textInput").className = "hide mt-4";
  };

  console.log(join);

  const submitResponse = async () => {
    if (join !== true && join !== false) {
      console.log(join);

      toast.error("Please select Yes/No");
      return;
    }
    // if (
    //   new Date(upcomingData?.user?.plan_valid_till) <= new Date() ||
    //   upcomingData?.number == 4
    // ) {
    //   const button = document.getElementById("toggle");
    //   button.click();

    //   const formData = {
    //     sessionId: id,
    //     not_joined_by: notJoin,
    //     isCompleted: join,
    //   };

    //   const { data } = await markAsComplete(formData);
    // } else {
    //   console.log("hitttt me");
    const formData = {
      sessionId: id,
      not_joined_by: notJoin,
      isCompleted: join,
    };

    const { data } = await markAsComplete(formData);
    if (!data.error) {
      navigate("/clinician/video-session-management");
    }
  };

  const allowForSilver = async (id, value) => {
    const formData = {
      userId: id,
      status: value,
    };
    const { data } = await allowForSilverPlan(formData);
    if (!data.error) {
      const button = document.getElementById("closeModal");
      button.click();
      $(".modal-backdrop").remove();
      navigate("/clinician/video-session-management");
    }
  };

  return (
    <>
      {" "}
      <div class="admin_main">
        <ClinicianSidebar />
        <div class="admin_main_inner">
          <ClinicianHeader />
          <div class="admin_panel_data height_adjust">
            <div class="row user-details-part upcoming_view justify-content-center">
              <div class="col-12 mb-4 main_heading text-center">
                <h1>Upcoming</h1>
              </div>
              <div class="col-12 design_outter_comman shadow mb-4">
                <div class="row comman_header justify-content-between">
                  <div class="col-auto">
                    <h2>User's Information</h2>
                  </div>
                </div>
                <form class="row mx-0 align-items-center justify-content-center form-design position-relative p-4 py-5">
                  <div class="col-3">
                    <div class="row">
                      <div class="form-group col-12 mb-2">
                        <div class="userinfor_box text-center">
                          <span class="user_imgg">
                            <img
                              src={upcomingData?.user?.profile_image}
                              alt=""
                            />
                          </span>
                          <strong>
                            {upcomingData?.user?.full_name}{" "}
                            <div class="sub_name">
                              ({upcomingData?.user?.type})
                            </div>
                          </strong>
                        </div>
                      </div>
                      <div className="form-group col-12 text-center mb-2">
                        <label className="mb-0 text-center" for="">
                          Registration Date:{" "}
                          {moment(upcomingData?.user?.createdAt).format("L")}
                        </label>
                        <label className="mb-0 text-center" for="">
                          Plan Start Date:{" "}
                          {moment(upcomingData?.user?.plan_valid_from).format(
                            "L"
                          )}
                        </label>
                        <label className="mb-0 text-center" for="">
                          Plan End Date:{" "}
                          {moment(upcomingData?.user?.plan_valid_till).format(
                            "L"
                          )}
                        </label>
                        <label className="mb-0 text-center" for="">
                          Plan Type: {upcomingData?.user?.planId?.plan_name}
                        </label>
                      </div>
                      <div class="form-group col-12 text-center mb-2">
                        <label class="mb-0 text-center" for="">
                          Subscription Date:{" "}
                          {moment(upcomingData?.user?.plan_valid_from).format(
                            "L"
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col px-4">
                    <div class="row">
                      <div class="form-group col-12">
                        <label for="">
                          Pairing Member ({upcomingData?.user?.partner?.type}):
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={upcomingData?.user?.partner?.full_name}
                          name="name"
                          id="name"
                        />
                      </div>
                      <div class="form-group col-12">
                        <label for="">Pairing Member Email: </label>
                        <input
                          type="text"
                          class="form-control"
                          value={upcomingData?.user?.partner?.email}
                          name="name"
                          id="name"
                        />
                      </div>
                      <div class="form-group col-12 mb-0">
                        <label for="">Relationship: </label>
                        <input
                          type="text"
                          class="form-control"
                          value={upcomingData?.user?.relationship}
                          name="name"
                          id="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col pe-5">
                    <div class="row">
                      <div class="form-group col-12">
                        <label for="">Mobile Number:</label>
                        <input
                          type="text"
                          class="form-control"
                          value={upcomingData?.user?.phone_number}
                          name="name"
                          id="name"
                        />
                      </div>
                      <div class="form-group col-12">
                        <label for="">Email Id: </label>
                        <input
                          type="text"
                          class="form-control"
                          value={upcomingData?.user?.email}
                          name="name"
                          id="name"
                        />
                      </div>
                      {upcomingData?.user?.appleId ? (
                        <div class="form-group col-12 mb-0">
                          <label for="">Apple Id: </label>
                          <input
                            type="text"
                            class="form-control"
                            value="User@apple.com"
                            name="name"
                            id="name"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              </div>

              {upcomingData?.user?.planId?.plan_type == 1 ? (
                <>
                  <div class="row comman_header justify-content-between">
                    <div class="col">
                      <h2>
                        Monthly Plan's Question & Answer by{" "}
                        {upcomingData?.user?.type}
                      </h2>
                    </div>
                  </div>
                  <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                    {upcomingData?.user?.answers?.length ? (
                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {upcomingData?.user?.answers?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === index1
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndex1(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {upcomingData.user.answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index1}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index1}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      upcomingData?.user?.answers[index1]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  {upcomingData?.user?.answers[
                                    index1
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      upcomingData.user.answers[index1].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${upcomingData?.user?.answers[index1]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            upcomingData.user.answers[index1]
                                              .answer
                                          }
                                        />
                                        <label
                                          for={`radio1${upcomingData?.user?.answers[index1]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {upcomingData?.user?.answers_3month?.length ? (
                      <div class="row">
                        <label>Question & Answer 3rd Month</label>
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {upcomingData?.user?.answers_3month?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS2
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS2(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>

                          {upcomingData?.user?.answers_3month ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${indexS2}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${indexS2}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      upcomingData?.user?.answers_3month[
                                        indexS2
                                      ]?.questionId?.question
                                    }
                                  </h3>

                                  {upcomingData?.user?.answers_3month[
                                    indexS2
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      upcomingData?.user?.answers_3month[
                                        indexS2
                                      ].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${upcomingData?.user?.answers_3month[indexS2]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            upcomingData?.user?.answers_3month[
                                              indexS2
                                            ].answer
                                          }
                                        />
                                        <label
                                          for={`radio1${upcomingData?.user?.answers_3month[indexS2]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {upcomingData?.user?.answers_6month?.length ? (
                      <div class="row">
                        <label>Question & Answer 6th Month</label>
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {upcomingData?.user?.answers_6month?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS3
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS3(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {upcomingData?.user?.answers_6month ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${indexS3}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${indexS3}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      upcomingData?.user?.answers[indexS3]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  {upcomingData?.user?.answers_6month[
                                    indexS3
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      upcomingData?.user?.answers_6month[
                                        indexS3
                                      ].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${upcomingData?.user?.answers_6month[indexS3]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            upcomingData?.user?.answers_6month[
                                              indexS3
                                            ].answer
                                          }
                                        />
                                        <label
                                          for={`radio1${upcomingData?.user?.answers_6month[indexS3]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {upcomingData?.user?.answers_9month?.length ? (
                      <div class="row">
                        <label>Question & Answer 9th Month</label>
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {upcomingData?.user?.answers_9month?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS4
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS4(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {upcomingData?.user?.answers_9month ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${indexS4}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${indexS4}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      upcomingData?.user?.answers[indexS4]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  {upcomingData?.user?.answers_9month[
                                    indexS4
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      upcomingData?.user?.answers_9month[
                                        indexS4
                                      ].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${upcomingData?.user?.answers_9month[indexS4]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            upcomingData?.user?.answers_9month[
                                              indexS4
                                            ].answer
                                          }
                                        />
                                        <label
                                          for={`radio1${upcomingData?.user?.answers_9month[indexS4]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {upcomingData?.user?.partner?.answers?.length ? (
                      <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                        <div class="row comman_header justify-content-between">
                          <div class="col">
                            <h2>
                              Monthly Plan's Question & Answer by{" "}
                              {upcomingData?.user?.partner?.type}
                            </h2>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {upcomingData?.user?.partner.answers?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {upcomingData.user.partner.answers ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        upcomingData?.user?.partner.answers[
                                          index2
                                        ]?.questionId?.question
                                      }
                                    </h3>

                                    {upcomingData?.user?.partner.answers[
                                      index2
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          upcomingData.user.partner.answers[
                                            index2
                                          ].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${upcomingData?.user?.partner.answers[index2]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                upcomingData.user.partner
                                                  .answers[index2].answer
                                              }
                                            />
                                            <label
                                              for={`radio1${upcomingData?.user?.partner.answers[index2]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {upcomingData?.user?.partner?.answers_3month
                              ?.length ? (
                              <div class="row">
                                <label>Question & Answer 3rd Month</label>
                                <div class="col-12 user_tabbing py-4 px-4">
                                  <nav>
                                    <div
                                      class="nav nav-tabs d-flex justify-content-start border-0"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      {upcomingData?.user?.partner?.answers_3month?.map(
                                        (item, index) => {
                                          return (
                                            <button
                                              class={
                                                index === index2S2
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                              id={`nav-${index}-tab`}
                                              data-bs-toggle="tab"
                                              data-bs-target={`#nav-${index}`}
                                              type="button"
                                              role="tab"
                                              aria-controls={`nav-${index}`}
                                              aria-selected="true"
                                              onClick={() => setIndex2S2(index)}
                                            >
                                              {index + 1}
                                            </button>
                                          );
                                        }
                                      )}
                                    </div>
                                  </nav>
                                  {upcomingData?.user?.partner
                                    ?.answers_3month ? (
                                    <div
                                      class="tab-content mt-4"
                                      id="nav-tabContent"
                                    >
                                      <div
                                        class="tab-pane fade show active"
                                        id={`nav-${index2S2}`}
                                        role="tabpanel"
                                        aria-labelledby={`nav-${index2S2}-tab`}
                                      >
                                        <form class="row question_ansbox justify-content-center">
                                          <h3 class="mb-4">
                                            {
                                              upcomingData?.user?.partner
                                                ?.answers_3month[index2S2]
                                                ?.questionId?.question
                                            }
                                          </h3>

                                          {upcomingData?.user?.partner?.answers_3month[
                                            index2S2
                                          ]?.questionId?.options?.map(
                                            (item, index) => {
                                              console.log(
                                                upcomingData?.user?.partner
                                                  ?.answers_3month[index2S2]
                                                  .answer,
                                                item
                                              );
                                              return (
                                                <div class="form-group col-auto custom_radio">
                                                  <input
                                                    type="radio"
                                                    id={`radio1${upcomingData?.user?.partner?.answers_3month[index2S2]?.questionId}${index}`}
                                                    name="radio1"
                                                    class="d-none"
                                                    checked={
                                                      item ===
                                                      upcomingData?.user
                                                        ?.partner
                                                        ?.answers_3month[
                                                        index2S2
                                                      ].answer
                                                    }
                                                  />
                                                  <label
                                                    for={`radio1${upcomingData?.user?.partner?.answers_3month[index2S2]?.questionId}${index}`}
                                                  >
                                                    {item}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {upcomingData?.user?.partner?.answers_6month
                              ?.length ? (
                              <div class="row">
                                <label>Question & Answer 6th Month</label>
                                <div class="col-12 user_tabbing py-4 px-4">
                                  <nav>
                                    <div
                                      class="nav nav-tabs d-flex justify-content-start border-0"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      {upcomingData?.user?.partner?.answers_9month?.map(
                                        (item, index) => {
                                          return (
                                            <button
                                              class={
                                                index === index2S3
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                              id={`nav-${index}-tab`}
                                              data-bs-toggle="tab"
                                              data-bs-target={`#nav-${index}`}
                                              type="button"
                                              role="tab"
                                              aria-controls={`nav-${index}`}
                                              aria-selected="true"
                                              onClick={() => setIndex2S3(index)}
                                            >
                                              {index + 1}
                                            </button>
                                          );
                                        }
                                      )}
                                    </div>
                                  </nav>
                                  {upcomingData?.user?.partner
                                    ?.answers_6month ? (
                                    <div
                                      class="tab-content mt-4"
                                      id="nav-tabContent"
                                    >
                                      <div
                                        class="tab-pane fade show active"
                                        id={`nav-${index2S3}`}
                                        role="tabpanel"
                                        aria-labelledby={`nav-${index2S3}-tab`}
                                      >
                                        <form class="row question_ansbox justify-content-center">
                                          <h3 class="mb-4">
                                            {
                                              upcomingData?.user?.partner
                                                ?.answers_6month[index2S3]
                                                ?.questionId?.question
                                            }
                                          </h3>

                                          {upcomingData?.user?.partner?.answers_6month[
                                            index2S3
                                          ]?.questionId?.options?.map(
                                            (item, index) => {
                                              console.log(
                                                upcomingData?.user
                                                  ?.answers_6month[index2S3]
                                                  .answer,
                                                item
                                              );
                                              return (
                                                <div class="form-group col-auto custom_radio">
                                                  <input
                                                    type="radio"
                                                    id={`radio1${upcomingData?.user?.partner?.answers_6month[index2S3]?.questionId}${index}`}
                                                    name="radio1"
                                                    class="d-none"
                                                    checked={
                                                      item ===
                                                      upcomingData?.user
                                                        ?.partner
                                                        ?.answers_6month[
                                                        index2S3
                                                      ].answer
                                                    }
                                                  />
                                                  <label
                                                    for={`radio1${upcomingData?.user?.partner?.answers_6month[index2S3]?.questionId}${index}`}
                                                  >
                                                    {item}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {upcomingData?.user?.partner?.answers_9month
                              ?.length ? (
                              <div class="row">
                                <label>Question & Answer 9th Month</label>
                                <div class="col-12 user_tabbing py-4 px-4">
                                  <nav>
                                    <div
                                      class="nav nav-tabs d-flex justify-content-start border-0"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      {upcomingData?.user?.partner?.answers_9month?.map(
                                        (item, index) => {
                                          return (
                                            <button
                                              class={
                                                index === index2S4
                                                  ? "nav-link active"
                                                  : "nav-link"
                                              }
                                              id={`nav-${index}-tab`}
                                              data-bs-toggle="tab"
                                              data-bs-target={`#nav-${index}`}
                                              type="button"
                                              role="tab"
                                              aria-controls={`nav-${index}`}
                                              aria-selected="true"
                                              onClick={() => setIndex2S4(index)}
                                            >
                                              {index + 1}
                                            </button>
                                          );
                                        }
                                      )}
                                    </div>
                                  </nav>
                                  {upcomingData?.user?.partner
                                    ?.answers_9month ? (
                                    <div
                                      class="tab-content mt-4"
                                      id="nav-tabContent"
                                    >
                                      <div
                                        class="tab-pane fade show active"
                                        id={`nav-${index2S4}`}
                                        role="tabpanel"
                                        aria-labelledby={`nav-${index2S4}-tab`}
                                      >
                                        <form class="row question_ansbox justify-content-center">
                                          <h3 class="mb-4">
                                            {
                                              upcomingData?.user?.answers[
                                                index2S4
                                              ]?.questionId?.question
                                            }
                                          </h3>

                                          {upcomingData?.user?.partner?.answers_9month[
                                            index2S4
                                          ]?.questionId?.options?.map(
                                            (item, index) => {
                                              console.log(
                                                upcomingData?.user?.partner
                                                  ?.answers_9month[index2S4]
                                                  .answer,
                                                item
                                              );
                                              return (
                                                <div class="form-group col-auto custom_radio">
                                                  <input
                                                    type="radio"
                                                    id={`radio1${upcomingData?.user?.partner?.answers_9month[index2S4]?.questionId}${index}`}
                                                    name="radio1"
                                                    class="d-none"
                                                    checked={
                                                      item ===
                                                      upcomingData?.user
                                                        ?.partner
                                                        ?.answers_9month[
                                                        index2S4
                                                      ].answer
                                                    }
                                                  />
                                                  <label
                                                    for={`radio1${upcomingData?.user?.partner?.answers_9month[index2S4]?.questionId}${index}`}
                                                  >
                                                    {item}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {upcomingData?.user?.gold_answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Intake Plan's Question & Answer by{" "}
                            {upcomingData.user.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {upcomingData?.user?.gold_answers?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === index1
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndex1(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {upcomingData.user.gold_answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index1}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index1}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      upcomingData?.user?.gold_answers[index1]
                                        ?.questionId?.question
                                    }
                                  </h3>
                                  <label>
                                    {upcomingData?.user?.gold_answers[index1]
                                      ?.answer.length > 1
                                      ? upcomingData?.user?.gold_answers[
                                          index1
                                        ]?.answer.map((item, index) => {
                                          return (
                                            <>
                                              <label>{item}</label> <br />
                                            </>
                                          );
                                        })
                                      : upcomingData?.user?.gold_answers[index1]
                                          ?.answer}
                                  </label>
                                </form>
                                {upcomingData?.user?.gold_answers[index1]
                                  ?.answer2 ? (
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        upcomingData?.user?.gold_answers[index1]
                                          ?.questionId?.question2
                                      }
                                    </h3>
                                    <label>
                                      {
                                        upcomingData?.user?.gold_answers[index1]
                                          ?.answer2
                                      }
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {upcomingData?.user?.partner?.gold_answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Intake Plan's Question & Answer by{" "}
                            {upcomingData?.user?.partner?.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {upcomingData?.user?.partner.gold_answers?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === index2
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndex2(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {upcomingData.user.partner.gold_answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index2}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index2}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      upcomingData?.user?.partner.gold_answers[
                                        index2
                                      ]?.questionId?.question
                                    }
                                  </h3>

                                  <label>
                                    {upcomingData?.user?.partner?.gold_answers[
                                      index2
                                    ]?.answer.length > 1
                                      ? upcomingData?.user?.partner?.gold_answers[
                                          index2
                                        ]?.answer.map((item, index) => {
                                          return (
                                            <>
                                              <label>{item}</label> <br />
                                            </>
                                          );
                                        })
                                      : upcomingData?.user?.partner
                                          ?.gold_answers[index2]?.answer}
                                  </label>
                                </form>
                                {upcomingData?.user?.partner?.gold_answers[
                                  index2
                                ]?.answer2 ? (
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        upcomingData?.user?.partner
                                          ?.gold_answers[index2]?.questionId
                                          ?.question2
                                      }
                                    </h3>
                                    <label>
                                      {
                                        upcomingData?.user?.partner
                                          ?.gold_answers[index2]?.answer2
                                      }
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}

              {upcomingData?.user?.mother_telehealth_consent?.name ||
              upcomingData?.user?.daughter_telehealth_consent?.name ||
              upcomingData?.user?.mother_npp?.name1 ||
              upcomingData?.user?.daughter_npp?.name1 ? (
                <div class="col-12 design_outter_comman shadow mb-4">
                  <div class="row comman_header justify-content-between">
                    <div class="col-auto">
                      <h2>Forms Signed by Mother & Daughter</h2>
                    </div>
                  </div>
                  <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                    <div className="row">
                      {upcomingData?.user?.mother_telehealth_consent?.name ? (
                        <div className="col-6">
                          <a
                            href={`/clinician/telehealth-consent-form/${id}/Mother`}
                            class=""
                            target="_blank"
                          >
                            <div class="comman_btn2 me-3">
                              Telehealth Consent By Mother
                            </div>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {upcomingData?.user?.mother_npp?.name1 ? (
                        <div className="col-6 ">
                          <a
                            href={`/clinician/notice-of-privacy-policies-form/${id}/Mother`}
                            class=""
                            target="_blank"
                          >
                            <div class="comman_btn2 me-3">
                              NPP & Practice Policies By Mother
                            </div>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {upcomingData?.user?.daughter_telehealth_consent?.name ? (
                        <div className="col-6 mt-4">
                          <a
                            href={`/clinician/telehealth-consent-form/${id}/Daughter`}
                            class=""
                            target="_blank"
                          >
                            <div class="comman_btn2 me-3">
                              Telehealth Consent By Daughter
                            </div>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      {upcomingData?.user?.daughter_npp?.name1 ? (
                        <div className="col-6 mt-4">
                          <a
                            href={`/clinician/notice-of-privacy-policies-form/${id}/Daughter`}
                            class=""
                            target="_blank"
                          >
                            <div class="comman_btn2 me-3">
                              NPP & Practice Policies By Daughter
                            </div>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}

              <div class="col-12 design_outter_comman shadow mb-4">
                <div class="row comman_header justify-content-between">
                  <div class="col-auto">
                    <h2>Session Schedule</h2>
                  </div>
                </div>
                <form
                  class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                  action=""
                >
                  <div class="form-group mb-0 col-6">
                    <label for="">Date</label>
                    <input
                      type="text"
                      class="form-control"
                      value={moment(upcomingData?.date).format("L")}
                      name="name"
                      id="name"
                    />
                  </div>
                  <div class="form-group mb-0 col-6">
                    <label for="">Time</label>
                    <input
                      type="text"
                      class="form-control"
                      value={moment(upcomingData?.date).format("hh:mm A")}
                      name="name"
                      id="name"
                    />
                  </div>

                  <div class="form-group col-12 text-center mt-4 mb-0 show_cancel">
                    <a
                      href={upcomingData.joinURL}
                      class={
                        upcomingData.canStartSession
                          ? "me-3"
                          : " a_disable me-3"
                      }
                      target="_blank"
                    >
                      <div class="comman_btn2 me-3">Video Session</div>
                    </a>

                    {upcomingData.canReschedule ? (
                      <button
                        type="button"
                        class="comman_btn me-3"
                        onClick={() => onButtonClick()}
                      >
                        Cancel
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      type="button"
                      class="comman_btn"
                      onClick={() => onButtonClick2()}
                    >
                      Mark as complete
                    </button>

                    <div class="row hide" id="textInput">
                      <div class="col-5 pe-4">
                        <input
                          required
                          class="form-control"
                          type="text"
                          onChange={(e) => setCompReason(e.target.value)}
                          placeholder="Type your cancellation reason."
                        />
                      </div>
                      <div class="col-auto">
                        <a onClick={() => getCancelReason()} class="comman_btn">
                          Submit
                        </a>
                      </div>
                    </div>

                    <div class="row hide" id="textInput2">
                      <form class="row">
                        <div className=" d-flex justify-content-between">
                          <p className="text-nowrap mt-3 mx-4">
                            {" "}
                            Are you sure this session is completed?
                          </p>
                          <div class="form-group col-2 text-start custom_radio mt-3">
                            <input
                              type="radio"
                              id="radio1"
                              name="radio1"
                              class="d-none"
                              onClick={() => setJoin(true)}
                            />
                            <label for="radio1">Yes</label>
                          </div>
                          <div class="form-group col-4 text-start custom_radio mt-3">
                            <input
                              type="radio"
                              id="radio2"
                              name="radio1"
                              class="d-none"
                              onClick={() => setJoin(false)}
                            />
                            <label for="radio2">No</label>
                          </div>
                        </div>

                        <div class="col-12 text-center">
                          <Link
                            to=""
                            onClick={() => submitResponse()}
                            class="comman_btn"
                          >
                            Submit
                          </Link>
                        </div>

                        {join === false ? (
                          <div class="form-group col-12 multiple_select_design">
                            <label for="">Not Joined By?</label>
                            <select
                              class="form-select"
                              onChange={(e) => setNotJoin(e.target.value)}
                            >
                              <option value="0" selected>
                                Select
                              </option>
                              <option value="User">User</option>
                              <option value="Clinician">Clinician</option>
                            </select>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-12 mb-4 main_heading text-center">
                    <h1>Session History</h1>
                  </div>
                  <div class="col-12 design_outter_comman shadow mb-4">
                    <div class="row py-4 px-4">
                      <div class="col-12 mb-4">
                        {getSession?.length ? (
                          <OwlCarousel
                            className="slider_session owl-carousel"
                            autoplay={false}
                            autoplayHoverPause={true}
                            autoplayTimeout={5000}
                            dots={false}
                            loop={false}
                            autoWidth={false}
                            nav={true}
                            fade={true}
                            items={getSession.length}
                            stagePadding={20}
                          >
                            {getSession?.map((item, index) => (
                              <Link
                                class="slider_menu item active"
                                onClick={() => {
                                  setMonthIndex(index);
                                  setSessionIndex(0);
                                }}
                                to=""
                              >
                                {item.month.month}
                              </Link>
                            ))}
                          </OwlCarousel>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        id="video_list_1"
                        class="video_list col-12 session_history_tabs px-0 border rounded overflow-hidden"
                      >
                        <nav>
                          <div
                            class="nav two_navtabs nav-tabs justify-content-center border-0"
                            id="nav-tab"
                            role="tablist"
                          >
                            {console.log(getSession, "kjlj")}
                            {(getSession[monthIndex]?.sessions || [])?.map(
                              (item, index) => (
                                <button
                                  class={
                                    sessionIndex === index
                                      ? "nav-link active"
                                      : "nav-link"
                                  }
                                  id={`nav-home-tab${index}`}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#nav-${index}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`nav-${index}`}
                                  aria-selected="true"
                                  onClick={() => setSessionIndex(index)}
                                >
                                  {item.name}
                                </button>
                              )
                            )}
                          </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id={`nav-${sessionIndex}`}
                            role="tabpanel"
                            aria-labelledby={`nav-home-tab${sessionIndex}`}
                          >
                            <div class="row mx-0 p-4">
                              <div class="col-12 design_outter_comman border rounded mb-4">
                                <div class="row comman_header justify-content-between">
                                  <div class="col-auto">
                                    <h2>Session Schedule</h2>
                                  </div>
                                </div>
                                <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                  <div class="form-group mb-0 col">
                                    <label for="">Date</label>
                                    <input
                                      type="type"
                                      class="form-control"
                                      value={
                                        getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ]?.date
                                          ? moment(
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ]?.date
                                            ).format("L")
                                          : ""
                                      }
                                      name="name"
                                      id="name"
                                    />
                                  </div>
                                  <div class="form-group mb-0 col">
                                    <label for="">Time</label>
                                    <input
                                      type="type"
                                      class="form-control"
                                      value={
                                        getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ]?.time
                                          ? moment(
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ]?.date
                                            ).format("hh:mm A")
                                          : ""
                                      }
                                      name="name"
                                      id="name"
                                    />
                                  </div>
                                  <div class="form-group mb-0 col">
                                    <label for="">Duration</label>
                                    <input
                                      type="type"
                                      class="form-control"
                                      value={
                                        getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ]?.duration
                                          ? getSession[monthIndex]?.sessions[
                                              sessionIndex
                                            ]?.duration
                                          : ""
                                      }
                                      name="name"
                                      id="name"
                                    />
                                  </div>
                                </form>
                              </div>

                              {getSession[monthIndex]?.sessions[sessionIndex]
                                ?.plan_type === 1 ? (
                                <>
                                  <div class="col-12 design_outter_comman border rounded mb-4">
                                    <div class="row comman_header justify-content-between">
                                      <div class="col-auto">
                                        <h2>Assigned Activity</h2>
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Activity"
                                      ).activity ? (
                                        <div className="col-auto">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop07"
                                            className="edit_form"
                                            onClick={() => {
                                              setEditActivity(true);

                                              setSessionId(
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Activity"
                                                ).session
                                              );
                                            }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                      <div class="form-group col mb-0 choose_file position-relative">
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Activity"
                                        ).activity?.image ? (
                                          <div class="user_response_box me-3">
                                            <img
                                              src={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Activity"
                                                ).activity?.image
                                              }
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            <Link
                                              class="comman_btn"
                                              to=""
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop07"
                                              onClick={() =>
                                                setSessionId(
                                                  getSession[monthIndex]
                                                    .sessions[sessionIndex]._id
                                                )
                                              }
                                            >
                                              Select Activity
                                            </Link>
                                          </>
                                        )}
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Activity"
                                      ).activity?.message ? (
                                        <div class="form-group col ">
                                          <label for="">Message</label>

                                          <input
                                            type="text"
                                            class="form-control"
                                            value={
                                              getActivities(
                                                getSession[monthIndex]
                                                  ?.activities,
                                                "Activity"
                                              ).activity?.message
                                            }
                                            name="name"
                                            id="name"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </form>
                                    <div class="row mx-0 user_response text-center pb-4 align-items-center">
                                      <div className="col-6">
                                        <div class="col-12 mb-2">
                                          <label for="">
                                            Mother's Response
                                          </label>
                                        </div>
                                        <div class="col-12">
                                          <div class="d-flex align-items-center justify-content-start">
                                            <div class="user_response_box me-3">
                                              <img
                                                src={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).mother_response
                                                    ? getActivities(
                                                        getSession[monthIndex]
                                                          ?.activities,
                                                        "Activity"
                                                      ).mother_response
                                                    : "/assets/img/women.png"
                                                }
                                                alt=""
                                              />
                                            </div>

                                            {getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Activity"
                                            ).mother_response ? (
                                              <a
                                                class="comman_btn2"
                                                href={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).mother_response ?? ""
                                                }
                                                target="_blank"
                                              >
                                                <i class="fal fa-download me-2"></i>
                                                Download
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div class="col-12 mb-2">
                                          <label for="">
                                            Daughter's Response
                                          </label>
                                        </div>
                                        <div class="col-12">
                                          <div class="d-flex align-items-center justify-content-start">
                                            <div class="user_response_box me-3">
                                              <img
                                                src={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).daughter_response
                                                    ? getActivities(
                                                        getSession[monthIndex]
                                                          ?.activities,
                                                        "Activity"
                                                      ).daughter_response
                                                    : "/assets/img/women.png"
                                                }
                                                alt=""
                                              />
                                            </div>

                                            {getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Activity"
                                            ).daughter_response ? (
                                              <a
                                                class="comman_btn2"
                                                href={
                                                  getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Activity"
                                                  ).daughter_response ?? ""
                                                }
                                                target="_blank"
                                              >
                                                <i class="fal fa-download me-2"></i>
                                                Download
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 design_outter_comman border rounded mb-4">
                                    <div class="row comman_header justify-content-between">
                                      <div class="col-auto">
                                        <h2>Suggest A Journal</h2>
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Journal"
                                      ).journal ? (
                                        <div className="col-auto">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop08"
                                            className="edit_form"
                                            onClick={() => {
                                              setEditJournal(true);

                                              setSessionId(
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).session
                                              );
                                            }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                      <div class="form-group col-10">
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Journal"
                                        ).journal?.topic ? (
                                          <>
                                            <label for="">Journal Topic</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              value={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).journal?.topic
                                              }
                                              name="name"
                                              id="name"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Link
                                              class="comman_btn"
                                              to=""
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop08"
                                              onClick={() =>
                                                setSessionId(
                                                  getSession[monthIndex]
                                                    .sessions[sessionIndex]._id
                                                )
                                              }
                                            >
                                              Select Journal
                                            </Link>
                                          </>
                                        )}
                                      </div>

                                      <div class="form-group col-12 mb-0">
                                        <label for="">Mother's Response</label>
                                      </div>
                                      <div class="form-group col-10">
                                        <input
                                          type="text"
                                          class="form-control"
                                          value={
                                            getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Journal"
                                            ).mother_response
                                          }
                                          name="name"
                                          id="name"
                                        />
                                      </div>

                                      <div class="form-group col-4 ">
                                        <div class="row  align-items-center">
                                          {getActivities(
                                            getSession[monthIndex]?.activities,
                                            "Journal"
                                          ).mother_audio ? (
                                            <a
                                              className="comman_btn2 text-center w-50"
                                              target="_blank"
                                              href={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).mother_audio
                                              }
                                              // src="http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"
                                            >
                                              Play
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div class="form-group col-12 mb-0 ">
                                        <label for="">
                                          Daughter's Response
                                        </label>
                                      </div>
                                      <div class="form-group col-10">
                                        <input
                                          type="text"
                                          class="form-control"
                                          value={
                                            getActivities(
                                              getSession[monthIndex]
                                                ?.activities,
                                              "Journal"
                                            ).daughter_response
                                          }
                                          name="name"
                                          id="name"
                                        />
                                      </div>

                                      <div class="form-group col-4 mb-0">
                                        <div class="row  align-items-center">
                                          {getActivities(
                                            getSession[monthIndex]?.activities,
                                            "Journal"
                                          ).daughter_audio ? (
                                            <a
                                              className="comman_btn2 text-center w-50"
                                              href={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Journal"
                                                ).daughter_audio
                                              }
                                              // src="http://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Sevish_-__nbsp_.mp3"

                                              target="_blank"
                                            >
                                              Play
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div class="col-12 design_outter_comman border rounded mb-4">
                                    <div class="row comman_header justify-content-between">
                                      <div class="col-auto d-flex">
                                        <h2>Assigned Worksheet</h2>
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Worksheet"
                                        )?.isCompletedByDaughter ||
                                        getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Worksheet"
                                        )?.isCompletedByMother ? (
                                          <a
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={
                                              getActivities(
                                                getSession[monthIndex]
                                                  ?.activities,
                                                "Worksheet"
                                              )?.isCompletedByDaughter &&
                                              getActivities(
                                                getSession[monthIndex]
                                                  ?.activities,
                                                "Worksheet"
                                              )?.isCompletedByMother
                                                ? "Complete By Mother & Daughter"
                                                : getActivities(
                                                    getSession[monthIndex]
                                                      ?.activities,
                                                    "Worksheet"
                                                  )?.isCompletedByMother
                                                ? "Complete By Mother"
                                                : "Complete By Daughter"
                                            }
                                            data-tooltip-place="top"
                                          >
                                            <i
                                              className="fa fa-check-circle ms-2"
                                              style={{
                                                color: "#fff",
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                        <Tooltip id="my-tooltip" />
                                      </div>

                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Worksheet"
                                      ).worksheet ? (
                                        <div className="col-auto">
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop09"
                                            className="edit_form"
                                            onClick={() => {
                                              setEditWorksheet(true);
                                              setSessionId(
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Worksheet"
                                                ).session
                                              );
                                            }}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                                      <div class="form-group col  choose_file position-relative">
                                        {getActivities(
                                          getSession[monthIndex]?.activities,
                                          "Worksheet"
                                        ).worksheet?.pdf ? (
                                          <>
                                            <a
                                              href={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Worksheet"
                                                ).worksheet?.pdf
                                              }
                                              target="_blank"
                                            >
                                              <i
                                                className="fa fa-file-pdf-o"
                                                style={{
                                                  fontSize: "65px",
                                                  color: "#000",
                                                  marginBottom: "-10px",
                                                }}
                                              ></i>
                                            </a>
                                          </>
                                        ) : (
                                          <Link
                                            class="comman_btn"
                                            to=""
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop09"
                                            onClick={() =>
                                              setSessionId(
                                                getSession[monthIndex].sessions[
                                                  sessionIndex
                                                ]._id
                                              )
                                            }

                                            // onClick={() => assignedActivity()}
                                          >
                                            Select Worksheet
                                          </Link>
                                        )}
                                      </div>
                                      {getActivities(
                                        getSession[monthIndex]?.activities,
                                        "Worksheet"
                                      ).worksheet?.message ? (
                                        <>
                                          <div class="form-group col ">
                                            <label for="">Message</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              value={
                                                getActivities(
                                                  getSession[monthIndex]
                                                    ?.activities,
                                                  "Worksheet"
                                                ).worksheet?.message
                                              }
                                              name="name"
                                              id="name"
                                            />
                                          </div>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </form>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {getSession[monthIndex]?.sessions[sessionIndex]
                                .plan_type == 1 ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      <>
                                                        {" "}
                                                        <span>Upload Pdf</span>
                                                        <label for="upload_pdf1">
                                                          <i class="fal fa-camera me-1"></i>
                                                          Choose File
                                                        </label>
                                                        <input
                                                          type="file"
                                                          accept=".png, .jpg, .jpeg"
                                                          class="form-control form-control1"
                                                          name="upload_pdf1"
                                                          id="upload_pdf1"
                                                          onChange={(e) =>
                                                            onFileSelection2(
                                                              e.target.files
                                                            )
                                                          }
                                                        />{" "}
                                                      </>
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">My Feedback</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="feedback"
                                        name="feedback"
                                        {...register("feedback", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "feedback",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.feedback && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Focus Of Session</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="focus_of_session"
                                        name="focus_of_session"
                                        {...register("focus_of_session", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "focus_of_session",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.focus_of_session && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Progress Toward Goals
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="progress_toward_goals"
                                        name="progress_toward_goals"
                                        {...register("progress_toward_goals", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "progress_toward_goals",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.progress_toward_goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Safety Concerns</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="safety_concerns"
                                        name="safety_concerns"
                                        {...register("safety_concerns", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "safety_concerns",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.safety_concerns && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "plan", "session1");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Addendum Note</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="change_reason"
                                        name="change_reason"
                                        {...register("change_reason", {
                                          required: false,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "change_reason",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={true}
                                      />
                                      {errors?.change_reason && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6   choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group mb-0 mt-4 col-12 mt-4">
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100 "
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100 d-none"
                                              type="submit"
                                              id="submitBtn4"
                                              onClick={() => setType(2)}
                                            >
                                              Sign
                                            </button>
                                            <a
                                              class="comman_btn w-100 text-center"
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdropSign"
                                              onClick={() => setType(2)}
                                            >
                                              Sign
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button class="comman_btn ">
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : getSession[monthIndex]?.sessions[sessionIndex]
                                  .plan_type == 2 &&
                                getSession[monthIndex].sessions[sessionIndex]
                                  .number === 1 ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      {getSession[monthIndex]
                                                        ?.sessions[sessionIndex]
                                                        .pdf ? (
                                                        <div class="user_response_box me-3">
                                                          <img
                                                            src={
                                                              getSession[
                                                                monthIndex
                                                              ]?.sessions[
                                                                sessionIndex
                                                              ].pdf
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <span>
                                                            Upload Pdf
                                                          </span>
                                                          <label for="upload_pdf1">
                                                            <i class="fal fa-camera me-1"></i>
                                                            Choose File
                                                          </label>
                                                          <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            class="form-control form-control1"
                                                            name="upload_pdf1"
                                                            id="upload_pdf1"
                                                            onChange={(e) =>
                                                              onFileSelection2(
                                                                e.target.files
                                                              )
                                                            }
                                                          />{" "}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What prompted you to start this journey?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="start_reason"
                                        name="start_reason"
                                        {...register("start_reason", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "start_reason",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.start_reason && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What are your goals in starting Passport
                                        Journeys?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="goals"
                                        name="goals"
                                        {...register("goals", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "goals",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Have either of you been in
                                        counseling/therapy before either
                                        separately or together?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="therapy_history"
                                        name="therapy_history"
                                        {...register("therapy_history", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "therapy_history",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.therapy_history && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Orientation and Questions
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="focus_of_session"
                                        name="focus_of_session"
                                        {...register("focus_of_session", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "focus_of_session",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.focus_of_session && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Safety Concerns</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="safety_concerns"
                                        name="safety_concerns"
                                        {...register("safety_concerns", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "safety_concerns",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.safety_concerns && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Observations and Assessment
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="observations_and_assessment"
                                        name="observations_and_assessment"
                                        {...register(
                                          "observations_and_assessment",
                                          {
                                            required: type === 1 ? false : true,
                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "observations_and_assessment",
                                                "Introductory Meeting"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.observations_and_assessment && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "plan",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Addendum Note</label>
                                      <textarea
                                        defaultValue={userData?.change_reason}
                                        class="form-control textarea_"
                                        id="change_reason"
                                        name="change_reason"
                                        {...register("change_reason", {
                                          required: false,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "change_reason",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={true}
                                      />
                                      {errors?.change_reason && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                                      <label for="">
                                        Session Attendee Names
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="attendees"
                                        name="attendees"
                                        {...register("attendees", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "attendees",
                                              "Introductory Meeting"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.attendees && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group mb-0 mt-4 col-12 mt-4">
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100 "
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4 intake_im">
                                          <button
                                            class="comman_btn w-100 d-none "
                                            type="submit"
                                            id="submitBtn"
                                          >
                                            Sign
                                          </button>
                                          <a
                                            class="comman_btn w-100 text-center"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropSign"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </a>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button
                                                  class="comman_btn "
                                                  type="submit"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : getSession[monthIndex]?.sessions[sessionIndex]
                                  .plan_type === 2 &&
                                (getSession[monthIndex].sessions[sessionIndex]
                                  .number === 2 ||
                                  getSession[monthIndex].sessions[sessionIndex]
                                    .number === 3) ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      {getSession[monthIndex]
                                                        ?.sessions[sessionIndex]
                                                        .pdf ? (
                                                        <div class="user_response_box me-3">
                                                          <img
                                                            src={
                                                              getSession[
                                                                monthIndex
                                                              ]?.sessions[
                                                                sessionIndex
                                                              ].pdf
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <span>
                                                            Upload Pdf
                                                          </span>
                                                          <label for="upload_pdf1">
                                                            <i class="fal fa-camera me-1"></i>
                                                            Choose File
                                                          </label>
                                                          <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            class="form-control form-control1"
                                                            name="upload_pdf1"
                                                            id="upload_pdf1"
                                                            onChange={(e) =>
                                                              onFileSelection2(
                                                                e.target.files
                                                              )
                                                            }
                                                          />{" "}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        How would you describe your
                                        mother-daughter relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="mother_daughter_relationship"
                                        name="mother_daughter_relationship"
                                        {...register(
                                          "mother_daughter_relationship",
                                          {
                                            required: type === 1 ? false : true,
                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "mother_daughter_relationship",
                                                "Ind-Mother"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.mother_daughter_relationship && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What are your goals for joining Passport
                                        Journeys?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="goals"
                                        name="goals"
                                        {...register("goals", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "goals",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What things work well within the
                                        relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="well_within_relationship"
                                        name="well_within_relationship"
                                        {...register(
                                          "well_within_relationship",
                                          {
                                            required: type === 1 ? false : true,
                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "well_within_relationship",
                                                "Ind-Mother"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.well_within_relationship && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What would you like to see improve in
                                        the relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="improve"
                                        name="improve"
                                        {...register("improve", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "improve",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.improve && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What do you hope for in your
                                        mother-daughter relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="hope"
                                        name="hope"
                                        {...register("hope", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "hope", "Ind-Mother");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.hope && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What do you identify as the biggest
                                        difficulty in the relationship?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="difficulty_in_relationship"
                                        name="difficulty_in_relationship"
                                        {...register(
                                          "difficulty_in_relationship",
                                          {
                                            required: type === 1 ? false : true,

                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "difficulty_in_relationship",
                                                "Ind-Mother"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.difficulty_in_relationship && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What is the most important change you
                                        would like to achieve in going on this
                                        journey?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="change"
                                        name="change"
                                        {...register("change", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "change",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.change && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Do you currently see a therapist? If no,
                                        what support do you have in place?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="therapy_history"
                                        name="therapy_history"
                                        {...register("therapy_history", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "therapy_history",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.therapy_history && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        What was helpful or not about your
                                        previous experience?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="previous_experience"
                                        name="previous_experience"
                                        {...register("previous_experience", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "previous_experience",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.previous_experience && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Is there other information you think is
                                        important for me to know before moving
                                        forward?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="other_info"
                                        name="other_info"
                                        {...register("other_info", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "other_info",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.other_info && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Review of red flags: (alcohol and/or
                                        drug use, active abuse in relationship,
                                        history of abuse, recent or unprocessed
                                        trauma)
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="red_flags_review"
                                        name="red_flags_review"
                                        {...register("red_flags_review", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "red_flags_review",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.red_flags_review && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Mental health screen: (past psychiatric
                                        history, suicidality, violence,
                                        pertinent medical history){" "}
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="mental_health_screen"
                                        name="mental_health_screen"
                                        {...register("mental_health_screen", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "mental_health_screen",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.mental_health_screen && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Social history</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="social_history"
                                        name="social_history"
                                        {...register("social_history", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "social_history",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.social_history && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Other pertinent information
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="other_pertinent_information"
                                        name="other_pertinent_information"
                                        {...register(
                                          "other_pertinent_information",
                                          {
                                            required: type === 1 ? false : true,
                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "other_pertinent_information",
                                                "Ind-Mother"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.other_pertinent_information && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Observations: (mental status exam)
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="observations"
                                        name="observations"
                                        {...register("observations", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "observations",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.observations && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Assessment: (suitability for this type
                                        of therapy)
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="assessment"
                                        name="assessment"
                                        {...register("assessment", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "assessment",
                                              "Ind-Mother"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.assessment && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "plan", "Ind-Mother");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Addendum Note</label>
                                      <textarea
                                        defaultValue={userData?.change_reason}
                                        class="form-control textarea_"
                                        id="change_reason"
                                        name="change_reason"
                                        {...register("change_reason", {
                                          required: false,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "change_reason",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={true}
                                      />
                                      {errors?.change_reason && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                                      <label for="">
                                        Session Attendee Names
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="attendees"
                                        name="attendees"
                                        {...register("attendees", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.attendees && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      class="form-group mb-0 mt-4 col-12 mt-4
                                    "
                                    >
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn  w-100"
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4">
                                          <button
                                            class="comman_btn w-100 d-none "
                                            type="submit"
                                            id="submitBtn2"
                                          >
                                            Sign
                                          </button>
                                          <a
                                            class="comman_btn w-100 text-center"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropSign"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </a>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button
                                                  class="comman_btn "
                                                  type="submit"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : getSession[monthIndex]?.sessions[sessionIndex]
                                  .plan_type == 2 &&
                                getSession[monthIndex].sessions[sessionIndex]
                                  .number === 4 ? (
                                <div class="col-12 design_outter_comman border rounded mb-4">
                                  <div class="row comman_header justify-content-between">
                                    <div class="col">
                                      <h2>Clinical Documentation</h2>
                                    </div>
                                    <div className="col-auto">
                                      <div class="form-group  mb-0 choose_file position-relative">
                                        {getSession[monthIndex]?.sessions[
                                          sessionIndex
                                        ].pdf ? (
                                          <a
                                            href={`${getSession[monthIndex]?.sessions[sessionIndex].pdf}`}
                                            target="blank"
                                          >
                                            <h2>Download Pdf</h2>
                                          </a>
                                        ) : (
                                          <Link to="">
                                            <h2
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop2"
                                            >
                                              Upload Pdf
                                            </h2>
                                          </Link>
                                        )}
                                        <div
                                          className="modal fade comman_modal"
                                          id="staticBackdrop2"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content border-0">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="staticBackdropLabel"
                                                ></h5>
                                                <button
                                                  type="button"
                                                  className="btn-close"
                                                  data-bs-dismiss="modal"
                                                  aria-label="Close"
                                                  id="close"
                                                ></button>
                                              </div>
                                              <div className="modal-body ">
                                                <div class="row">
                                                  <div class=" form-design">
                                                    <div class="form-group   choose_file position-relative">
                                                      {getSession[monthIndex]
                                                        ?.sessions[sessionIndex]
                                                        .pdf ? (
                                                        <div class="user_response_box me-3">
                                                          <img
                                                            src={
                                                              getSession[
                                                                monthIndex
                                                              ]?.sessions[
                                                                sessionIndex
                                                              ].pdf
                                                            }
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <span>
                                                            Upload Pdf
                                                          </span>
                                                          <label for="upload_pdf1">
                                                            <i class="fal fa-camera me-1"></i>
                                                            Choose File
                                                          </label>
                                                          <input
                                                            type="file"
                                                            accept=".png, .jpg, .jpeg"
                                                            class="form-control form-control1"
                                                            name="upload_pdf1"
                                                            id="upload_pdf1"
                                                            onChange={(e) =>
                                                              onFileSelection2(
                                                                e.target.files
                                                              )
                                                            }
                                                          />{" "}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-auto mx-auto my-3">
                                                    <Link
                                                      className="comman_btn"
                                                      to=""
                                                      onClick={() =>
                                                        uploadPdf()
                                                      }
                                                      data-bs-dismiss="modal"
                                                    >
                                                      Upload
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Clinician Feedback:Is the pair a good
                                        fit for Passport Journeys currently? If
                                        no, what recommendations were made?
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="feedback"
                                        name="feedback"
                                        {...register("feedback", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "feedback",
                                              "Treatment"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.feedback && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Feedback from mother-daughter pair
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="feedback_from_pair"
                                        name="feedback_from_pair"
                                        {...register("feedback_from_pair", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "feedback_from_pair",
                                              "Treatment"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.feedback_from_pair && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Goals for treatment</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="goals"
                                        name="goals"
                                        {...register("goals", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "goals", "Treatment");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.goals && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">Safety Concerns</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="safety_concerns"
                                        name="safety_concerns"
                                        {...register("safety_concerns", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "safety_concerns",
                                              "Treatment"
                                            );
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.safety_concerns && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 clinical_documentation">
                                      <label for="">
                                        Other pertinent information
                                      </label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="other_pertinent_information"
                                        name="other_pertinent_information"
                                        {...register(
                                          "other_pertinent_information",
                                          {
                                            required: type === 1 ? false : true,
                                            onChange: (e) => {
                                              handleData(
                                                e,
                                                "other_pertinent_information",
                                                "Treatment"
                                              );
                                            },
                                          }
                                        )}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.other_pertinent_information && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>

                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Plan</label>
                                      <textarea
                                        class="form-control textarea_"
                                        id="plan"
                                        name="plan"
                                        {...register("plan", {
                                          required: type === 1 ? false : true,
                                          onChange: (e) => {
                                            handleData(e, "plan", "Treatment");
                                          },
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.plan && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6 choose_file position-relative">
                                      {getSession[monthIndex]?.sessions[
                                        sessionIndex
                                      ].clinician_signature ? (
                                        <div class="user_response_box me-3">
                                          <img
                                            src={
                                              getSession[monthIndex]?.sessions[
                                                sessionIndex
                                              ].clinician_signature
                                            }
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <span>Upload Signature </span>
                                          <label for="upload_video">
                                            <i class="fal fa-camera me-1"></i>
                                            Choose File
                                          </label>
                                          <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            class="form-control"
                                            name="upload_video"
                                            id="upload_video"
                                            onChange={(e) =>
                                              onFileSelection(e.target.files)
                                            }
                                          />{" "}
                                        </>
                                      )}
                                    </div>

                                    <div class="form-group col-6 mb-0 clinical_documentation mt-4">
                                      <label for="">
                                        Session Attendee Names
                                      </label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="attendees"
                                        name="attendees"
                                        {...register("attendees", {
                                          required: type === 1 ? false : true,
                                        })}
                                        disabled={
                                          getSession[monthIndex].sessions[
                                            sessionIndex
                                          ].isSigned
                                            ? true
                                            : false
                                        }
                                      />
                                      {errors?.attendees && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-6  clinical_documentation">
                                      <label for="">Addendum Note</label>
                                      <textarea
                                        defaultValue={userData?.change_reason}
                                        class="form-control textarea_"
                                        id="change_reason"
                                        name="change_reason"
                                        {...register("change_reason", {
                                          required: false,
                                          onChange: (e) => {
                                            handleData(
                                              e,
                                              "change_reason",
                                              "session1"
                                            );
                                          },
                                        })}
                                        disabled={true}
                                      />
                                      {errors?.change_reason && (
                                        <p className="form-error mt-2">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group mb-0 mt-4 col-12 mt-4">
                                      <div className="row justify-content-center">
                                        {!getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <button
                                              class="comman_btn w-100"
                                              type="submit"
                                              onClick={() => setType(1)}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-4">
                                          <button
                                            class="comman_btn w-100 d-none"
                                            type="submit"
                                            id="submitBtn3"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </button>
                                          <a
                                            class="comman_btn w-100 text-center"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdropSign"
                                            onClick={() => setType(2)}
                                          >
                                            Sign
                                          </a>
                                        </div>
                                        {getSession[monthIndex].sessions[
                                          sessionIndex
                                        ].isSigned ? (
                                          <div className="col-4">
                                            <Link
                                              class=" text-center comman_btn w-100 "
                                              onClick={() => setType(3)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#staticBackdrop"
                                              to=""
                                            >
                                              Addendum
                                            </Link>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade"
                                      id="staticBackdrop"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content border-0">
                                          <div class="modal-body text-end">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              id="close_reason"
                                            ></button>
                                            <div class="row">
                                              <div class="col-12 Update_modal_content text-center form-design ">
                                                <div class="form-group mb-4  clinical_documentation  mt-3 ">
                                                  <label for="">Comment</label>
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="reason"
                                                    name="reason"
                                                    onChange={(e) =>
                                                      setCompReason(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="col-12 mb-4 mt-2 d-flex justify-content-center">
                                                <button
                                                  class="comman_btn "
                                                  type="submit"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-body text-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close"
              ></button>
              <div class="row">
                <div class="col-12 Update_modal_content text-center py-4">
                  <h2>Cancel</h2>
                  <p>Are you sure, Want to Cancel this?</p>
                  <Link
                    class="comman_btn2 mx-2"
                    onClick={() => getCancelReason()}
                    to=""
                  >
                    Yes
                  </Link>
                  <a
                    class="comman_btn mx-2"
                    data-bs-dismiss="modal"
                    href="javscript:;"
                  >
                    NO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdropSign"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-body text-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeMo"
              ></button>
              <div class="row">
                <div class="col-12 Update_modal_content text-center py-4">
                  <h2>Cancel</h2>
                  <p>Are you sure that you want to sign this note as final?</p>
                  <a
                    class="comman_btn2 mx-2"
                    href="#"
                    type="submit"
                    onClick={() => {
                      document.getElementById("closeMo").click();
                      document.getElementById("submitBtn").click();
                      document.getElementById("submitBtn2").click();
                      document.getElementById("submitBtn3").click();
                      document.getElementById("submitBtn4").click();
                    }}
                  >
                    Yes
                  </a>
                  <a
                    class="comman_btn mx-2"
                    data-bs-dismiss="modal"
                    href="javscript:;"
                  >
                    NO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade comman_modal"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-body text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closee"
              ></button>
              <div class="row">
                <div class="col-12 Update_modal_content text-center pb-4">
                  <h2>Continue</h2>
                  <p>Is the user a good fit for the monthly subscription?</p>
                  <Link
                    class="comman_btn2 mx-2"
                    onClick={() =>
                      allowForSilver(upcomingData?.user?._id, true)
                    }
                    to=""
                  >
                    Yes
                  </Link>
                  <Link
                    class="comman_btn mx-2"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      allowForSilver(upcomingData?.user?._id, false)
                    }
                    to=""
                  >
                    NO
                  </Link>
                </div>
                <p>
                  *Important:{" "}
                  <strong className="text-danger">
                    This should not be selected until AFTER the final intake
                    session is complete.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade assign_clinician comman_modal"
        id="staticBackdrop07"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <div className="col">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Select Activity
                </h5>
              </div>
              <div className="col-auto">
                <Link
                  className="edit_form"
                  to=""
                  data-bs-toggle="modal"
                  data-bs-target="#add-activity"
                  data-bs-dismiss="modal"
                >
                  <i className="fa fa-plus"></i>
                </Link>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close"
              ></button>
            </div>

            <div class=" p-4">
              <div class="col-12 p-0 help-support-table border border-2 border-bottom-0 rounded overflow-hidden border-light">
                <nav>
                  <div
                    class="nav nav-tabs justify-content-center border-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      class="nav-link nav-link-width active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={() => setRelation("In-person")}
                    >
                      In-Person
                    </button>

                    <button
                      class="nav-link nav-link-width"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => setRelation("Virtual")}
                    >
                      Virtual
                    </button>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div class="modal-body p-0">
                      <div class="row">
                        <div class="col-12 comman_table_design px-0">
                          <div class="table-responsive">
                            <MDBDataTable
                              bordered
                              hover
                              data={activity}
                              noBottomColumns
                              sortable
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div class="modal-body p-0">
                      <div class="row">
                        <div class="col-12 comman_table_design px-0">
                          <div class="table-responsive">
                            <MDBDataTable
                              bordered
                              hover
                              data={activity}
                              noBottomColumns
                              sortable
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade assign_clinician comman_modal"
        id="staticBackdrop08"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <div className="col">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Select Journal
                </h5>
              </div>
              <div className="col-auto">
                <Link
                  className="edit_form"
                  to=""
                  data-bs-toggle="modal"
                  data-bs-target="#add-journal"
                  data-bs-dismiss="modal"
                >
                  <i className="fa fa-plus"></i>
                </Link>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close2"
              ></button>
            </div>
            <div class="modal-body py-0">
              <div class="row">
                <div class="col-12 comman_table_design px-0">
                  <div class="table-responsive">
                    <MDBDataTable
                      bordered
                      hover
                      data={journal}
                      noBottomColumns
                      sortable
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade assign_clinician comman_modal"
        id="staticBackdrop09"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Select Worksheet
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close3"
              ></button>
            </div>
            <div class="modal-body py-0">
              <div class="row">
                <div class="col-12 comman_table_design px-0">
                  <div class="table-responsive">
                    <MDBDataTable
                      bordered
                      hover
                      data={worksheet}
                      noBottomColumns
                      sortable
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop2"
        id="toggle"
        className="d-none"
        to=""
      >
        {" "}
      </Link>
      <Link
        data-bs-dismiss="modal"
        aria-label="Close"
        id="closeModal"
        className="d-none"
        to=""
      >
        {" "}
      </Link>
      <div
        class="modal fade assign_clinician comman_modal"
        id="add-activity"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <div className="col">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Add Activity
                </h5>
              </div>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-activity"
              ></button>
            </div>
            <Activity sessionId={sessionId} update={getSessionDetail} />
          </div>
        </div>
      </div>
      <div
        class="modal fade assign_clinician comman_modal"
        id="add-journal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Add Journal
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-journal"
              ></button>
            </div>
            <Journal sessionId={sessionId} update={getSessionDetail} />
          </div>
        </div>
      </div>
      <div
        class="modal fade assign_clinician comman_modal"
        id="add-signs"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Confirmation
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-journal"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicianViewUpcoming;
